/* eslint-disable no-undef */
// 地图覆盖物展示
// 从服务端获取的覆盖物数据必须使用 MapOverlayVO.class 对象构建服务端数据
import { buildMarkerLabelOffset } from '../../../utils/utils'
import { mapState } from 'vuex'
// 定义 document.HTMLDOMtoString() 函数 将 HTMLDOM 转换成 HTMLDOM字符串
if (!document.HTMLDOMtoString) {
  /**
   * @return {string}
   */
  document.HTMLDOMtoString = function (HTMLDOM) {
    const div = document.createElement('div')
    div.appendChild(HTMLDOM)
    return div.innerHTML
  }
}

if (!String.width) {
  // eslint-disable-next-line no-extend-native
  String.prototype.width = function (font) {
    let f = font || '12px'
    const o = $('<div>' + this + '</div>')
      .css({ 'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font-size': f })
      .appendTo($('body'))
    const w = o.width()
    o.remove()
    return w
  }
}

const coverView = {
  computed: {
    ...mapState({
      bucketUrl: state => {
        return state.user.bucketUrl
      }
    })
  },
  data () {
    return {
      // {
      // key :'', 唯一key
      // cover:[], 覆盖物对象, 多边形和折线会有两个覆盖物
      // instance:{} 服务端返回数据
      // type:'' 标记类型
      // }
      // 页面上展示的所有已保存至数据库的覆盖物
      coverItems: [],
      // 标会点增加允许增加click方法 默认true 如果标会点不增加点击事件 请设置为false
      // true 情况下 需要监听 click事件 请 在引用类中重写 coverClick 方法
      // coverClick 收到对应点击的覆盖物对象 可通过extData获取覆盖物KEY
      // findCoverByKey 可通过KEY 查找到页面对应的覆盖物数据(coverItems中)
      allowClickEvent: true,
      defaultMarkerIcon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png',
      labelLayer: undefined,
      // view 默认浏览模式，浏览模式下 地图文本标注使用labelMarker 开启
      // edit 编辑模式下 地图文本标注使用textMarker textMarker方便文本标注位置修改
      type: 'view',
      overMarkTipIds: []
    }
  },
  methods: {
    // 通过实例数据 创建对应的标记 并添加至items中
    coverItemsAdd (instance) {
      const cover = this.buildCover(instance)
      this.coverItems.push(cover)
      return cover
    },

    /**
     * 构建覆盖物对象
     */
    buildCover (instance, isComposite) {
      const cover = {
        key: instance.id,
        type: instance.type,
        instance: instance,
        cover: [null, null]
      }
      const options = JSON.parse(instance.options)
      options.draggable = false
      options.bubble = false
      options['extData'] = {
        key: instance.id,
        module: instance.systemModule,
        id: instance.instanceId
      }
      if (instance.type === 'marker') {
        if (options.showTextMemo && options.label) {
          options.label['offset'] = eval('new AMap.Pixel'
            + buildMarkerLabelOffset(options.title,
              options.textMarker.fontSize + 'px',
              options.textMarker.offset, options.width || 33, options.height || 33))
        }
        // delete options.label
        // if (options.iconPath) {
        //   options['icon'] = this.buildMarkerIcon(this.bucketUrl + options.iconPath)
        // }
        if (options.showTips === undefined) {
          options.showTips = true
        }
        options['content'] = this.buildMarkerContent(options, instance.id, false, instance, isComposite)
        delete options.title
        cover.cover[0] = new AMap.Marker(options)
      } else if (instance.type === 'polyline') {
        cover.cover[0] = new AMap.Polyline(options)
      } else if (instance.type === 'polygon') {
        cover.cover[0] = new AMap.Polygon(options)
      } else if (instance.type === 'text') {
        if (this.type === 'edit') {
          cover.cover[0] = this.buildTextMarker(options, instance.id)
        } else {
          cover.cover[0] = this.buildTextLabelMarker(options, instance.id)
        }
      }

      if (this.allowClickEvent) {
        if (cover.cover[0]) {
          cover.cover[0].on('click', (event) => {
            this.coverClick(event.target)
          })
          cover.cover[0].on('rightclick', (event) => {
          })

          if (cover.type === 'marker' && options.showTips) {
            cover.cover[0].on('mouseout', (event) => {
             // const instanceId = event.target.getExtData()['key']
              //$('#marker-body-tips-' + this.mapContainerId + '-' + instanceId).remove()
              this.removeOverMarkTips();
            })
            cover.cover[0].on('mouseover', (event) => {
              const instanceId = event.target.getExtData()['key']
              this.removeOverMarkTips();
              this.overMarkTipIds.push('marker-body-tips-' + this.mapContainerId + '-' + instanceId)
              const $tips = $(this.getBodyTips('.marker-tips-' + this.mapContainerId + '-' + instanceId, this.mapContainerId, instanceId, options))
              $('body').append($tips)
              $tips.show('fast')
            })
          }
        }
      }
      return cover
    },

    buildMarkerContent (options, instanceId, type, instance, isComposite) {
      let animationClassId = this.mapContainerId;
      if (isComposite && instance && (instance.fault || instance.warn)) {
        animationClassId = this.mapContainerId + "faultWarn";
      }
      return '<div class="amap-icon custom-marker" style="position: absolute; width: ' + options.width + 'px; height: ' + options.height + 'px; opacity: 1;">' +
        this.getMarkerIconImg(options) +
        '</div>' +
        '<div class="marker-animation marker-animation-' + animationClassId + '-' + instanceId + '"' +
        ' style="' + this.getMarkerStyle(options, type, instance, isComposite)  + '">' +
        '</div>' +
        (options.showTips ? this.getTips(options, instanceId) : '')
    },
    getMarkerStyle (options, type, instance, isComposite) {
      var style = null;
      if (isComposite && instance && (instance.fault || instance.warn)) {
        style = ' right:' + options.hoverRight + 'px; bottom:' + options.hoverBottom + 'px;' +
          ' width:' + options.hoverWidth + 'px; height:' + options.hoverHeight + 'px; background-color:#ff0000'
      } else {
        style = (type ? '' : 'display: none;') + ' right:' + options.hoverRight + 'px; bottom:' + options.hoverBottom + 'px;' +
        ' width:' + options.hoverWidth + 'px; height:' + options.hoverHeight + 'px; background-color:' + options.hoverColor
      }
      return style;
    },
    getMarkerIconImg (options) {
      let iconPath = this.defaultMarkerIcon
      if (options.iconPath) {
        iconPath = this.bucketUrl + options.iconPath
      }
      return '<img src="' + iconPath + '" style="width: ' + options.width + 'px;height: ' + options.height + 'px;top: 0;left: 0;"/>'
    },

    getTips (options, instanceId) {
      return '<div style="visibility: hidden;width: ' + (options.title.width('14px') + 18) + 'px;top:' + options.height + 'px;left:' + options.width + 'px;" ' +
        'class="marker-tips marker-tips-' + this.mapContainerId + '-' + instanceId + '">' + options.title + '</div>'
    },

    getBodyTips (className, mapContainerId, instanceId, options) {
      const $html = $(className)
      // 需要根据当前页面大小进行判断，提示位置需要上下左右自适应，防止页面出现滚动条问题
      let top = $html.offset().top
      let left = $html.offset().left
      const documentHeight = document.body.offsetHeight
      const documentWidth = document.body.offsetWidth
      const width = parseInt($html.css('width').replace('px', ''))
      if (left + width > (documentWidth - options.width)) {
        left = left - width - options.width
      }
      if (top + 36 + options.height > documentHeight) {
        top = top - 36 - options.height
      }
      return '<div class="marker-tips" style="display: none;top:' + top + 'px;left:' + left + 'px;width: ' + width + ';" id="marker-body-tips-' + mapContainerId + '-' + instanceId + '">' + $html[0].textContent + '</div>'
    },

    setCoverToMap (coverObj, map) {
      if (coverObj.cover[0]) {
        if (coverObj.type === 'text' && this.type === 'view') {
          this.getLabelLayer(map).add(coverObj.cover[0])
        } else {
          coverObj.cover[0].setMap(map)
        }
      }
    },

    setCoversToMap (map) {
      this.coverItems.forEach(x => this.setCoverToMap(x, map))
    },

    /**
     * 覆盖物组添加到地图
     * @param overlayGroups
     */
    setOverlayGroupsToMap (overlayGroups, map) {
      if (overlayGroups) {
          overlayGroups.forEach((item) => {
            if (!item.overlayGroup.isAdded) {
              if (this.isCluster(item.key) && this.markerCluster) {
                 this.addMarkersToCluster(item.overlayGroup.getOverlays(), map)
                item.isInCluster = true
              } else {
                if (!map) return
                map.add(item.overlayGroup)
              }
              item.overlayGroup.isAdded = true
            }
          })
        }
    },
    /**
     * 添加聚合时  进行了判断 只对marker类型才能聚合
     * @param overlays
     */
    addMarkersToCluster (overlays, map) {
      overlays.forEach((ov) => {
        if (ov.CLASS_NAME === 'AMap.Marker') {
            if (!map) return
              this.markerCluster.addMarker(ov)
        } else {
          var extData =  ov.getExtData();
          if (extData != null) {
            console.log("聚合图层覆盖物类型错误:" + extData['module'] + 'id:' + extData['id'])
          } else {
            console.log("聚合图层覆盖物类型错误:" + item.key)
          }
          if (!map) return
          map.add(ov)
        }
      })
    },
    isCluster (groupKey) {
      var ret = false;
      let overLayConfig  =  null;
      if (this.findOverlayGroupByKey) {
        overLayConfig = this.findOverlayGroupConfig(groupKey)
      }
      if (overLayConfig && overLayConfig.isCluster === true) {
        return true;
      }
      return ret;
    },
    setCoverItems (values, map) {
      // 地图对象存在，会清楚地图上的所有覆盖物
      if (map) {
        map.clearMap()
      }
      this.coverItems = []
      values.forEach(x => {
        this.coverItemsAdd(x)
      })
    },

    getLabelLayer (map) {
      if (!this.labelLayer) {
        this.labelLayer = new AMap.LabelsLayer({
          rejectMapMask: true,
          // 开启标注避让，默认为开启，v1.4.15 新增属性
          collision: true,
          // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
          animation: true,
          zIndex: 100
        })
        map.add(this.labelLayer)
      }
      return this.labelLayer
    },

    // 创建LabelMarker
    buildTextLabelMarker (options, instanceId) {
      const _option = {
        position: options.position,
        zooms: options.zooms || [11, 18],
        name: options.text,
        text: {
          content: options.text,
          style: {
            padding: '3',
            fontSize: options.fontSize,
            fillColor: options.color,
            backgroundColor: options.backgroundColor
          }
        },
        extData: {
          key: instanceId
        }
      }
      return new AMap.LabelMarker(_option)
    },

    buildTextMarker (options, instanceId) {
      return new AMap.Text({
        anchor: 'center', // 设置文本标记锚点
        text: options.text,
        draggable: options.draggable,
        cursor: options.cursor,
        style: this.buildTextMarkerStyle(options),
        position: options.position,
        extData: {
          key: instanceId
        }
      })
    },

    buildTextMarkerStyle (options) {
      return {
        'padding': '3px',
        'border-radius': '.25rem',
        'background-color': options.backgroundColor || 'white',
        'width': 'auto',
        // 'border': options.border || '1px solid #e1e1e1',
        // 没有边框 labelMarker不支持边框，显示使用labelMarker
        'border': 'none',
        'text-align': 'center',
        'font-size': options.fontSize + 'px',
        'color': options.color
      }
    },

    findCoverByKey (key) {
      return this.coverItems.find(x => {
        return x.key === key
      })
    },

    /**
     * 查询 coverItems 中保存的覆盖物数据
     * module： base 基础点线面数据 其他和SystemModule一致
     *
     * @param instanceId ID数据
     * @param module 模块标识
     * @returns {*}
     */
    findCoverByInstanceId (instanceId, module) {
      return this.coverItems.find(x => {
        if (module === 'base') {
          return x.instance.id === instanceId
        } else {
          return x.instance.instanceId === instanceId
            && x.instance.systemModule === module
        }
      })
    },

    removeCoverByKey (key) {
      const index = this.coverItems.findIndex(x => {
        return x.key === key
      })
      if (index) {
        this.coverItems.splice(index, 1)
      }
    },

    coverClick (target) {
    },

    /**
     * 设置marker动画效果
     * @param marker
     * @param animation “AMAP_ANIMATION_NONE”，无动画效果；“AMAP_ANIMATION_DROP”；点标掉落效果；“AMAP_ANIMATION_BOUNCE”，点标弹跳效果
     */
    setMarkerAnimation (marker, animation) {
      if (marker) {
        marker.setAnimation(animation)
      }
    },
    /**
     * 移除点动画效果
     * @param marker
     */
    removeMarkerAnimation (marker) {
      if (marker) {
        marker.setAnimation('AMAP_ANIMATION_NONE')
      }
    },

    /**
     * 添加选中marker动画效果
     * @param marker
     */
    setSelectMarkerAnimation (marker) {
      $('.marker-animation-' + this.mapContainerId + '-' + marker.getExtData()['key']).show()
    },
    setTimeOutSelectedOerLayer (marker) {
      if (marker) {
        setTimeout(() => {
          if (marker.tOcount) {
            marker.tOcount++
          } else {
            marker.tOcount = 1
          }
          if ($('.marker-animation-' + this.mapContainerId + '-' + marker.getExtData()['key']).length > 0) {
            marker.tOcount = 1
            this.setSelectMarkerAnimation(marker)
          } else {
            if (marker.tOcount < 5) {
             this.setTimeOutSelectedOerLayer(marker)
            } else {
              marker.tOcount = 1
            }
          }
        }, 400)
      }
    },
    removeSelectMarkerAnimation (marker) {
      $('.marker-animation-' + this.mapContainerId + '-' + marker.getExtData()['key']).hide()
    },
    removeOverMarkTips () {
      if (this.overMarkTipIds && this.overMarkTipIds.length > 0) {
          this.overMarkTipIds.forEach(key => this.removeMarkTips(key))
          this.overMarkTipIds = []
      }
    },
    removeMarkTips (key) {
      $('#' + key).remove()
    }

  }
}

export default coverView
