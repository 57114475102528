import SERVICE_URLS from '../../../api/service.url'

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}

export const basicForm = {
  props: {
    chooseData: {}
  },
  data () {
    return {
      spinShow: false,
      formItemLayout: formItemLayout,
      showTextMemo: false,
      change: false,
      changeTime: 1000, // 毫秒
      form: this.$form.createForm(this, {
        // onValuesChange: (props, values) => {
        //   this.onValuesChangeHandle(props, values)
        // }
      }),
      ifSub: false,
      formType: '',
      type: 'add',
      dbData: {}
    }
  },
  methods: {
    showTitleOnChangeHandle () {
      this.$nextTick(() => {
        this.showTextMemo = this.form.getFieldValue('showTextMemo')
        this.showTextMemoOther()
      })
    },
    onValuesChangeHandle (props, values) {
      if (values['position']) return
      if (this.change) return
      this.change = true
      setTimeout(() => {
        this.$emit('onValuesChange', values)
        this.change = false
      }, this.changeTime)
    },
    showTextMemoOther () {
    },
    setLoading (value) {
      this.spinShow = value
    },
    setTitle (title) {
      this.form.resetFields()
      this.positionItems = []
      this.type = 'add'
      this.form.setFieldsValue({ title: title })
    },
    setDTOFieldsValue (values) {
    },
    submitAdd () {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(this.formType)
          if (this.formType !== 'text' && this.formType !== 'marker') {
            const path = this.getPositionArray(values)
            this.formItem.path = []
            this.formItem.path = path
            this.positionItems = path
          }
          const dto = {}
          this.setDTOFieldsValue(values)
          if (this.formType === 'text') {
            dto['name'] = this.formItem.text
          } else {
            dto['name'] = this.formItem.title
          }
          dto['type'] = this.formType
          dto['instanceId'] = this.chooseData.instanceId
          dto['systemModule'] = this.chooseData.systemModule
          dto['position'] = this.formItem.position
            ? JSON.stringify(this.formItem.position)
            : JSON.stringify(this.formItem.path)
          dto['options'] = JSON.stringify(this.formItem)
          this.$http(this, {
            url: SERVICE_URLS.overlay.create,
            data: dto,
            loading: 'ifSub',
            success: (data) => {
              this.type = 'edit'
              this.dbData = data.body
              this.$emit('addSuccess', data.body)
            },
            error: () => {
              this.$emit('addError')
            }
          })
        } else {
          console.error(err)
        }
      })
    },
    submitEdit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.formType !== 'text' && this.formType !== 'marker') {
            const path = this.getPositionArray(values)
            this.formItem.path = []
            this.formItem.path = path
            this.positionItems = path
          }
          this.setDTOFieldsValue(values)
          const dto = {}
          if (this.formType === 'text') {
            dto['name'] = this.formItem.text
          } else {
            dto['name'] = this.formItem.title
          }
          dto['type'] = this.formType
          dto['position'] = this.formItem.position
            ? JSON.stringify(this.formItem.position)
            : JSON.stringify(this.formItem.path)
          console.log(this.formItem.path)
          dto['options'] = JSON.stringify(this.formItem)
          this.$http(this, {
            url: SERVICE_URLS.overlay.update,
            params: {
              id: this.dbData.id
            },
            data: dto,
            loading: 'ifSub',
            success: (data) => {
              this.dbData = data.body
              this.setLabelPosition()
              this.$emit('editSuccess', data.body)
            },
            error: () => {
              this.$emit('editError')
            }
          })
        } else {
          console.error(err)
        }
      })
    },
    submitDelete () {
      const _this = this
      this.$confirm({
        title: '确认删除操作?',
        content: '此数据将会被删除',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          return new Promise((resolve, reject) => {
            _this.$http(_this, {
              url: SERVICE_URLS.overlay.delete,
              params: {
                id: _this.dbData.id
              },
              loading: 'ifSub',
              noTips: false,
              successTipsContent: '数据删除成功!',
              success: () => {
                _this.form.resetFields()
                _this.type = 'add'
                _this.$emit('deleteSuccess')
                resolve()
              }
            })
          }).catch(() => console.log('Oops delete errors!'))
        },
        onCancel () {
        }
      })
    },
    setEditData (values, type) {
      this.type = 'edit'
      this.dbData = values
      this.setFormFieldsValue(values, type)
    },
    setFormFieldsValue (values, type) {
    },
    setLabelPosition () {
    },
    resetForm () {
      this.form.resetFields()
      this.positionItems = []
      this.showTextMemo = true
      this.type = 'add'
      this.dbData = {}
    },
    getPositionArray (values) {
      const path = []
      values.position.forEach((data) => {
        const arr = data.split(',')
        const pos = []
        pos.push(Number(arr[0].slice(1, arr[0].length)), Number(arr[1].slice(0, arr[1].length - 1)))
        path.push(pos)
      })
      return path
    }
  }
}
