<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <a-form :form="form" class="form-container-body-form">
        <a-row>
          <a-form-item label="名称" v-bind="formItemLayout">
            <a-input v-show="!chooseData.systemModule"
                     v-decorator="['title',
                                      {rules:[
                                        {type:'string',required:true,message:'名称不能为空'}
                                        ],trigger:'change'}
                                      ]"
                     placeholder="请输入名称"></a-input>
            <span v-show="chooseData.systemModule">{{form.getFieldValue('title')}}</span>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="图片宽(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['width',
                                                                  {rules:[
                                                                    {required:true,message:'请输入图片宽'}
                                                                    ],trigger:'change',initialValue:33}
                                                                  ]"
                            placeholder="请输入图片宽"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="图片高(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['height',
                                                                  {rules:[
                                                                    {required:true,message:'请输入图片高'}
                                                                    ],trigger:'change',initialValue:33}
                                                                  ]"
                            placeholder="请输入图片高"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="图标" v-bind="formItemLayout">
            <a-col :span="4" v-if="formItem.iconPath">
              <img :width="formItem.width" :height="formItem.height"
                   :src="bucketUrl + formItem.iconPath" alt=""/>
            </a-col>
            <a-col :span="8">
              <qiniu-upload :notShowDefaultList="true"
                            :filters="filters"
                            :pickfiles="uploadId + 'pickfiles'"
                            :container="uploadId + 'container'"
                            @onBeforeUpload="onBeforeUpload"
                            @onOneUploadComplete="onOneUploadComplete">
                <div :id="uploadId + 'container'" slot="uploader">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>请选择图片</span>
                    </template>
                    <a-button type="primary"
                              size="small"
                              :loading="uploadStatus"
                              :id="uploadId + 'pickfiles'">
                      {{uploadStatus?'上传中'+parseInt(uploadFile.percent,10)+'%':'点击上传'}}
                    </a-button>
                  </a-tooltip>
                </div>
              </qiniu-upload>
            </a-col>
            <a-col :span="8">
              <a-button type="danger" size="small" v-if="formItem.iconPath" @click="removeIcon()">移除图片</a-button>
            </a-col>
            <a-col :span="24">
              {{formItem.iconPath}}
            </a-col>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="图标选择" v-bind="formItemLayout">
            <image-view title="[实心] 集污池" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1efttnu7e1o1nkj31gi63rregv1r.png"></image-view>
            <image-view title="[实心] 小型泵站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1efttnai84jg1kks15u2c3iijc1m.png"></image-view>
            <image-view title="[实心] 污水处理站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1efttmgqd1bq6ud61rqrq9ko91h.png"></image-view>
            <image-view title="[实心] 一体三格化粪池" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1efttl4ca1lo2fvq54aqtev971c.png"></image-view>
            <image-view title="集污池" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ec254c721l1n52rt4rdd9s879.png"></image-view>
            <image-view title="小型泵站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ec257ofroi51rk916c71s351t7k1h.png"></image-view>
            <image-view title="污水处理站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ec258ru2rtq1q69fl51oki112i1t.png"></image-view>
            <image-view title="站点" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1jsq76cmc19651pa9t6r1on44g.png"></image-view>
            <image-view title="单位" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1jqilb138sdc11blc1b5r1ukp3t.png"></image-view>
            <image-view title="泵站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1idfautef1dtnh2q1f2793eg.png"></image-view>
            <image-view title="提升泵站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1j486a1gfu1sg4quq186g1qpr1j.png"></image-view>
            <image-view title="小型泵站" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1jke2f14ci1fgia701hat1p803j.png"></image-view>
            <image-view title="井" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1ia0rb12jh1etv1u206mo1i7v15.png"></image-view>
            <image-view title="仓库" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1jo00sjo51rr1ddp3kb1shq3o.png"></image-view>
            <image-view title="集污池" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1k2shlo2h13j2k9jpuhb1b5h.png"></image-view>
            <image-view title="水闸" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1k9cu5176aim64oot6lt415m.png"></image-view>
            <image-view title="摄像头" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1ji42s1a27nkj16ir1h12jgt3e.png"></image-view>
            <image-view title="液位计" @selectIcon="selectIcon" :bucketUrl="bucketUrl"
                        name="o_1ea1kdjuf1lf5fqr1qqt1pob1hevl.png"></image-view>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="位置经纬度" v-bind="formItemLayout">
            <a-input
              v-decorator="['position',
                                              {rules:[
                                                {type:'string',required:true,message:'请点击选择位置经纬度'}
                                                ],trigger:'change'}
                                              ]"
              placeholder="请点击选择位置经纬度"></a-input>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="显示Tips" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['showTips',{initialValue:true}]">
              <a-radio :value="false">不显示</a-radio>
              <a-radio :value="true">显示</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="文本备注" v-bind="formItemLayout" required>
            <a-radio-group @change="showTitleOnChangeHandle" v-decorator="['showTextMemo',{initialValue:false}]">
              <a-radio :value="false">不显示</a-radio>
              <a-radio :value="true">显示</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <template v-if="showTextMemo">
          <a-divider style="font-size: 14px">备注文字设置</a-divider>
          <a-row>
            <a-form-item label="字体颜色" key="labelFontColor" v-bind="formItemLayout">
              <!--<a-input
                v-decorator="['labelFontColor',
                                                  {rules:[
                                                    {type:'string',required:true,message:'请输入字体颜色'}
                                                    ],trigger:'change',initialValue:'#111413'}
                                                  ]"
                placeholder="请输入字体颜色"></a-input>-->
              <chrome-color-picker
                v-decorator="['labelFontColor',
                                     {rules:[
                                       {type:'string',required:true,message:'请选择字体颜色'}
                                       ],trigger:'change',initialValue:'#5E82A5'}
                                     ]"
              ></chrome-color-picker>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="字体大小(px)" key="labelFontSize" v-bind="formItemLayout">
              <a-input-number style="width: 100%"
                              v-decorator="['labelFontSize',
                                                  {rules:[
                                                    {required:true,message:'请输入字体大小'}
                                                    ],trigger:'change',initialValue:12}
                                                  ]"
                              placeholder="请输入字体大小"></a-input-number>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="备注方向" key="labelOffset" v-bind="formItemLayout" required>
              <a-radio-group v-decorator="['labelOffset',{initialValue:'top'}]">
                <a-radio :value="'top'">上</a-radio>
                <a-radio :value="'right'">右</a-radio>
                <a-radio :value="'bottom'">下</a-radio>
                <a-radio :value="'left'">左</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="背景颜色" key="backgroundColor" v-bind="formItemLayout">
              <!--<a-input style="width: 100%"
                       v-decorator="['backgroundColor',
                                       {rules:[
                                         {required:true,message:'请输入背景颜色'}
                                         ],trigger:'change',initialValue:'white'}
                                       ]"
                       placeholder="请输入背景颜色"></a-input>-->
              <chrome-color-picker
                v-decorator="['backgroundColor',
                                     {rules:[
                                       {type:'string',required:true,message:'请选择背景颜色'}
                                       ],trigger:'change',initialValue:'#00000000'}
                                     ]"
              ></chrome-color-picker>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="背景边框" key="border" v-bind="formItemLayout">
              <a-input style="width: 100%"
                       v-decorator="['border',
                                         {rules:[
                                           {required:true,message:'请输入背景边框'}
                                           ],trigger:'change',initialValue:'0px solid #e1e1e1'}
                                         ]"
                       placeholder="请输入背景边框"></a-input>
            </a-form-item>
          </a-row>
        </template>
        <a-divider style="font-size: 14px">点标记选中效果设置</a-divider>
        <a-row>
          <a-form-item label="右偏移(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['hoverRight',
                                            {rules:[
                                              {required:true,message:'请输入右偏移'}
                                              ],trigger:'change',initialValue:24}
                                            ]"
                            placeholder="请输入右偏移"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="下偏移(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['hoverBottom',
                                            {rules:[
                                              {required:true,message:'请输入下偏移'}
                                              ],trigger:'change',initialValue:24}
                                            ]"
                            placeholder="请输入下偏移"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="宽度(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['hoverWidth',
                                            {rules:[
                                              {required:true,message:'请输入宽度'}
                                              ],trigger:'change',initialValue:80}
                                            ]"
                            placeholder="请输入宽度"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="高度(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['hoverHeight',
                                            {rules:[
                                              {required:true,message:'请输入高度'}
                                              ],trigger:'change',initialValue:80}
                                            ]"
                            placeholder="请输入高度"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="背景颜色" v-bind="formItemLayout">
            <!--<a-input
              v-decorator="['hoverColor',
                           {rules:[
                             {type:'string',required:true,message:'请输入背景颜色'}
                             ],trigger:'change',initialValue:'#2d8cf0'}
                           ]"
              placeholder="请输入背景颜色"></a-input>-->
            <chrome-color-picker
              v-decorator="['hoverColor',
                     {rules:[
                       {type:'string',required:true,message:'请输入选择颜色'}
                       ],trigger:'change',initialValue:'#2d8cf0'}
                     ]"
            ></chrome-color-picker>
          </a-form-item>
        </a-row>
      </a-form>
    </div>
    <a-row class="m-b-12 m-r-24 m-t-12">
      <a-button type="primary" class="f-r" @click="submitAdd" :loading="ifSub" v-if="type === 'add'">添加</a-button>
      <a-button type="primary" class="f-r m-l-12" @click="submitEdit" :loading="ifSub" v-if="type === 'edit'">更新
      </a-button>
      <a-button type="danger" class="f-r" @click="submitDelete" :loading="ifSub" v-if="type === 'edit'">删除</a-button>
    </a-row>
  </a-spin>
</template>

<script>
  /* eslint-disable no-undef */

  import marker from '../../api/map/domain/marker'
  import { basicForm } from './mixins/common'
  import { mapState } from 'vuex'
  import { buildMarkerLabelOffset } from '../../utils/utils'
  import QiniuUpload from '../../components/QiniuUpload'
  import ImageView from '../../components/QiniuUpload/ImageView'
  import UUID from '../../utils/UUID'
  import { ChromeColorPicker } from '../../components/color-picker'

  export default {
    name: 'MarkerForm',
    mixins: [basicForm],
    components: { QiniuUpload, ImageView, ChromeColorPicker },
    data () {
      return {
        formItem: marker(),
        formType: 'marker',
        uploadId: new UUID(),
        filters: {
          mime_types: [ //只允许上传图片
            { title: '只允许上传图片', extensions: 'jpg,gif,png' }
          ]
        },
        uploadStatus: false,
        uploadFile: {}
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 300
        },
        bucketUrl: state => {
          return state.user.bucketUrl
        }
      })
    },
    methods: {
      selectIcon (name) {
        this.$nextTick(() => {
          this.formItem.iconPath = name
        })
      },
      setPosition (value) {
        this.form.setFieldsValue({
          position: value
        })
      },
      getFormData () {
        this.formItem.position = eval(this.form.getFieldValue('position'))
        this.formItem.offset = new AMap.Pixel(-13, -30)
        this.formItem.title = this.form.getFieldValue('title')
        this.formItem.showTextMemo = this.form.getFieldValue('showTextMemo')
        this.formItem.showTips = this.form.getFieldValue('showTips')
        this.formItem.width = this.form.getFieldValue('width')
        this.formItem.height = this.form.getFieldValue('height')
        this.formItem.hoverRight = this.form.getFieldValue('hoverRight')
        this.formItem.hoverBottom = this.form.getFieldValue('hoverBottom')
        this.formItem.hoverHeight = this.form.getFieldValue('hoverHeight')
        this.formItem.hoverWidth = this.form.getFieldValue('hoverWidth')
        this.formItem.hoverColor = this.form.getFieldValue('hoverColor')
        if (this.showTextMemo && this.formItem.title) {
          this.formItem.label = {}
          this.formItem.textMarker = {}
          this.formItem.textMarker.offset = this.form.getFieldValue('labelOffset')
          this.formItem.textMarker.color = this.form.getFieldValue('labelFontColor')
          this.formItem.textMarker.fontSize = this.form.getFieldValue('labelFontSize')
          this.formItem.textMarker.backgroundColor = this.form.getFieldValue('backgroundColor')
          this.formItem.textMarker.border = this.form.getFieldValue('border')
          this.formItem.label['offset'] = eval('new AMap.Pixel'
            + buildMarkerLabelOffset(this.formItem.title,
              this.formItem.textMarker.fontSize + 'px',
              this.formItem.textMarker.offset, this.formItem.width, this.formItem.height))
          this.formItem.label['content'] = '<div class="info" style="color:' + this.form.getFieldValue('labelFontColor')
            + ';font-size: ' + this.form.getFieldValue('labelFontSize') + 'px;border:' + this.formItem.textMarker.border + ';' +
            'background-color: ' + this.formItem.textMarker.backgroundColor + '">'
            + this.formItem.title + '</div>'
        } else {
          delete this.formItem.label
          delete this.formItem.textMarker
        }

        this.formItem['draggable'] = false

        return this.formItem
      },
      setDTOFieldsValue (values) {
        return this.getFormData()
      },
      setFormFieldsValue (values, type) {
        const options = JSON.parse(values.options)
        this.showTextMemo = options.showTextMemo
        Object.assign(this.formItem, options)
        this.formItem.iconPath = options.iconPath
        this.$nextTick(() => {
          this.form.setFieldsValue({
            position: values.position,
            title: values.name,
            showTextMemo: options.showTextMemo,
            showTips: options.showTips === undefined ? true : options.showTips,
            hoverBottom: options.hoverBottom || 9,
            hoverRight: options.hoverRight || 16,
            hoverWidth: options.hoverWidth || 50,
            hoverHeight: options.hoverHeight || 50,
            hoverColor: options.hoverColor || '#2d8cf0',
            height: options.height || 33,
            width: options.width || 33
          })
          if (this.showTextMemo) {
            this.form.setFieldsValue({
              labelOffset: options.textMarker.offset,
              labelFontColor: options.textMarker.color,
              labelFontSize: options.textMarker.fontSize,
              backgroundColor: options.textMarker.backgroundColor || 'white',
              border: options.textMarker.border || '1px solid #e1e1e1'
            })
          }
          if (!type) {
            this.$emit('formFieldsSetSuccess')
          }
        })
      },
      // 图标上传
      onOneUploadComplete (data) {
        this.uploadStatus = false
        this.formItem['iconPath'] = data.fileUrl
        this.$emit('onUploadSuccess')
      },
      onBeforeUpload (up, file) {
        this.uploadFile = file
        this.uploadStatus = true
      },
      removeIcon () {
        this.formItem['iconPath'] = undefined
        this.$emit('onUploadSuccess')
      }
    }
  }
</script>