<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <a-row align="middle">
        <a-col :span="6" v-for="(item,index) in typeList" :key="index" style="text-align: center">
          <a-button type="primary" style="width: 74px" @click="addCover(item.value)">{{ item.label }}</a-button>
        </a-col>
      </a-row>
      <div style="padding:20px 20px 20px  20px;">
        <a-list :bordered="false" :dataSource="searchData">
          <a-list-item slot="renderItem" class="cursor-pointer default-hover"
                       slot-scope="item" @click.native="clickItem(item)">
            <div style="width: 100%">
              <a-icon type="environment" v-if="item.type==='marker'" style="margin-right: 10px;"/>
              <a-icon type="dash" v-if="item.type==='polyline'" style="margin-right: 10px;"/>
              <a-icon type="radius-setting" v-if="item.type==='polygon'" style="margin-right: 10px;"/>
              <a-icon type="tag" v-if="item.type==='text'" style="margin-right: 10px;"/>
              {{item.name}}
            </div>
          </a-list-item>
          <div slot="footer">
            <a-pagination size="small"
                          :current="pagination.current"
                          :pageSize="pagination.pageSize"
                          :total="pagination.total"
                          :showTotal="pagination.showTotal"
                          @change="onPageChange"/>
          </div>
        </a-list>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { mapState } from 'vuex'

  export default {
    name: 'SearchDeviceForm',
    props: {
      searchKey: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        searchParams: {
          number: 0,
          size: 10,
          direction: '',
          order: '',
          name: '',
          type: '',
          status: 'off',
          systemModule: null
        },
        searchData: [],
        typeList: [
          { value: 'marker', label: '标点' },
          { value: 'polyline', label: '标线' },
          { value: 'polygon', label: '标面' },
          { value: 'text', label: '标备注' }
        ],
        spinShow: false
      }
    },
    watch: {
      searchKey () {
        this.searchParams.name = this.searchKey
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 200
        }
      })
    },
    created () {
    },
    methods: {
      /*类型改变*/
      typeChange (value) {
        this.searchParams.type = value
        this.onRefresh('rest')
      },
      clickItem (item) {
        this.$emit('selectItem', 'base', item.id, item.name, item.type)
      },
      addCover (value) {
        this.$emit('addCover', value)
      },
      /*分页改变*/
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.onRefresh()
      },
      /*查询方法*/
      onSearch () {
        this.onRefresh('rest')
      },
      onRefresh (restPage) {
        if (restPage) {
          this.searchParams.number = 0
        }
        this.$http(this, {
          url: SERVICE_URLS.overlay.search,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.number + 1
          },
          error: () => {
          }
        })
      },
      setNameById (name, id) {
        const temp = this.searchData.find(x => x.id === id)
        if (temp) {
          temp.name = name
        }
      }
    }
  }
</script>