const marker = () => {
  return {
    position: null,
    zIndex: 100,
    offset: null,
    icon: null,
    iconPath: null,
    topWhenClick: true,
    bubble: true,
    draggable: true,
    title: null,
    clickable: true,
    height: 33,
    width: 33,
    showTips: true,
    hoverRight: 24,
    hoverBottom: 24,
    hoverWidth: 80,
    hoverHeight: 80,
    hoverColor: '#2d8cf0',
    label: {
      content: null,
      offset: null,
      direction: 'top'
    },
    showTextMemo: false, // 展示备注信息在地图上
    textMarker: {
      text: null,
      fontSize: 12,
      color: '#5E82A5',
      offset: null,
      backgroundColor: "#00000000",
      border: '0px solid #e1e1e1'
    }
  }
}

export default marker

