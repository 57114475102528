// 图层
import SERVICE_URLS from '../../../api/service.url'

const layer = {
  data() {
    return {
      layerType: [
        // {
        //   label: '全部',
        //   value: 'all',
        // },
        // {
        //   label: '在线设备',
        //   value: 'device_info',
        // },
        {
          label: '设备/设施',
          value: 'monitoring_point',
        },
        // {
        //   label: '设施',
        //   value: 'facility_info',
        // },
        // {
        //   label: '仓库',
        //   value: 'device_depot',
        // },
        {
          label: '点线面',
          value: 'base',
        },
      ],
      checkLayer: 'none',
      loadLayerData: false,
    }
  },
  methods: {
    handleCheckLayerType(value) {
      this.map.off('click', this.mapClick)
      this.formShow = false
      this.checkLayer = value
      // 加载数据
      if (this.loadLayerData) return
      this.$http(this, {
        url: SERVICE_URLS.overlay.searchAll,
        data: {
          module: value,
        },
        loading: 'loadLayerData',
        noTips: true,
        success: (data) => {
          this.setCoverItems(data.body, this.map)
          this.setCoversToMap(this.map)
          if (!this.map) return
          this.map.setFitView()
        },
        error: () => {},
      })
    },
    closeLayer() {
      this.checkLayer = 'none'
      this.formShow = false
      this.map.clearMap()
    },
  },
}

export default layer
