export const polygon = () => {
  return {
    title: '',
    zIndex: 10,
    path: [],
    bubble: false,
    cursor: 'pointer',
    strokeColor: '#1890ff', // 线条颜色，使用16进制颜色代码赋值。默认值为#006600
    strokeOpacity: 0.9, // 轮廓线透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
    strokeWeight: 2, // 轮廓线宽度
    strokeStyle: 'solid', // 轮廓线样式，实线:solid，虚线:dashed
    // 勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效， 此属性在ie9+浏览器有效 取值：
    // 实线：[0,0,0]
    // 虚线：[10,10] ，[10,10] 表示10个像素的实线和10个像素的空白（如此反复）组成的虚线
    // 点画线：[10,2,10]， [10,2,10] 表示10个像素的实线和2个像素的空白 + 10个像素的实线和10个像素的空白 （如此反复）组成的虚线
    strokeDasharray: [10, 10],
    fillColor: '#FFAA00', // 多边形填充颜色，使用16进制颜色代码赋值，如：#FFAA00
    fillOpacity: 0.9, // 多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
    draggable: true
  }
}